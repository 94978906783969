import React from 'react';
import { IPatientAnswer, IBaseParams, ICopyAnswersRequest } from 'common/models/formBuilder.models';
import { FormStatus } from 'common/components/Form/FormStatus';
import { DailyTreatmentSummaryMobile } from 'app/pages/DailyTreatmentSummaryMobile';
import { DiaryMedicationsMobile } from 'app/pages/Diary/Medications/DiaryMedicationsMobile';
import { BodyAreasPage } from 'app/pages/BodyAreasPage';
import { TableFormBuilder } from 'app/pages/TableFormBuilder';
import { PainAreasPage } from 'app/pages/PainAreasPage';
import { PromisPage } from 'app/pages/PromisPage';
import { DiaryOptionsPage } from 'app/pages/DiaryOptionsPage';
import { ThanksPage } from 'app/pages/ThanksPage';
import { PretherapyStartPage } from 'app/pages/PretherapyStart';
import { PretherapyFinishPage } from 'app/pages/PretherapyFinish';
import { DiaryFormBuilder } from 'app/pages/DiaryFormBuilder';
import { FormBuilder } from 'app/pages/FormBuilder';
import { DiaryThanksPage } from 'app/pages/Diary/DiaryThanksPage';
import { IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IStatus {
  loading: boolean;
  success: boolean;
  errors: boolean;
}

export const getQuestionnare = (
  id: string,
  questionnaire: IQuestionnaireModel,
  handleSubmit: (params: IBaseParams, update?: boolean) => void,
  setPatientAnswers: React.Dispatch<React.SetStateAction<IPatientAnswer[]>>,
  getAnswersCopy: (params: ICopyAnswersRequest) => Promise<IQuestionnaireModel>,
  status: IStatus,
  patientAnswers: IPatientAnswer[],
  patientSessionId: string,
  getPrevPatientSessionsModel: (id: string) => void
): React.ReactNode => {
  const drawStatusBar = (message?: string) => {
    return <FormStatus loading={status.loading} errors={status.errors} success={status.success} message={message} />;
  };

  switch (id) {
    case 'baseline_common_body':
      return <BodyAreasPage id={id} handleSubmit={handleSubmit} />;
    case 'baseline_body':
      return <PainAreasPage id={id} handleSubmit={handleSubmit} sessionId={patientSessionId} />;
    case 'baseline_head':
    case 'baseline_body_left_arm':
    case 'baseline_body_right_arm':
    case 'baseline_body_left_hand':
    case 'baseline_body_right_hand':
    case 'baseline_body_chest_abdomen_back':
    case 'baseline_body_legs':
    case 'baseline_body_feet':
    case 'baseline_medical_history':
    case 'baseline_hit_six':
    case 'baseline_wpai_migraine':
    case 'baseline_msq_version_two_one':
    case 'baseline_phq_2':
    case 'baseline_gad_2':
    case 'baseline_taps_1':
    case 'baseline_demographics':
      return (
        <>
          {drawStatusBar()}
          <FormBuilder
            questionnaire={questionnaire}
            handleSubmit={handleSubmit}
            patientAnswers={patientAnswers}
            patientSessionId={patientSessionId}
            setPatientAnswers={setPatientAnswers}
            getAnswersCopy={getAnswersCopy}
          />
        </>
      );
    case 'baseline_pain_questions':
    case 'baseline_bed_time':
    case 'baseline_symptoms_list':
    case 'baseline_medications_list':
    case 'pretherapy_typical_pain_levels_expected_later_today':
    case 'pretherapy_symptoms_list':
      return (
        <>
          {drawStatusBar()}
          <TableFormBuilder questionnaire={questionnaire} handleSubmit={handleSubmit} patientAnswers={patientAnswers} />
        </>
      );
    case 'baseline_promis':
    case 'diary_promis':
      return (
        <>
          {drawStatusBar()}
          <PromisPage questionnaire={questionnaire} handleSubmit={handleSubmit} />
        </>
      );
    case 'baseline_diary_options':
      return (
        <>
          {drawStatusBar()}
          <DiaryOptionsPage questionnaire={questionnaire} handleSubmit={handleSubmit} />
        </>
      );
    case 'baseline_finish':
      return <ThanksPage questionnaire={questionnaire} handleSubmit={handleSubmit} />;
    case 'pretherapy_start':
      return <PretherapyStartPage handleSubmit={handleSubmit} />;
    case 'pretherapy_finish':
      return <PretherapyFinishPage />;
    case 'diary_daily_treatment_summary':
      return (
        <>
          {drawStatusBar()}
          <DailyTreatmentSummaryMobile questionnaire={questionnaire} handleSubmit={handleSubmit} />
        </>
      );
    case 'diary_medications_list':
      return (
        <>
          {drawStatusBar()}
          <DiaryMedicationsMobile
            questionnaire={questionnaire}
            handleSubmit={handleSubmit}
            patientSessionId={patientSessionId}
            getPrevPatientSessionsModel={getPrevPatientSessionsModel}
            patientAnswers={patientAnswers}
          />
        </>
      );
    case 'diary_head_symptoms':
    case 'diary_chest_abdomen_back_symptoms':
    case 'diary_feet_symptoms':
    case 'diary_left_arm_symptoms':
    case 'diary_left_hand_symptoms':
    case 'diary_legs_symptoms':
    case 'diary_right_arm_symptoms':
    case 'diary_right_hand_symptoms':
    case 'diary_dvprs':
    case 'diary_blinding_questions':
    case 'diary_pgic':
    case 'diary_hit_six':
    case 'diary_wpai_migraine':
    case 'diary_msq_version_two_one':
    case 'diary_phq_2':
    case 'diary_gad_2':
    case 'diary_demographics':
    case 'diary_taps_1':
      return (
        <>
          {drawStatusBar()}
          <DiaryFormBuilder
            questionnaire={questionnaire}
            handleSubmit={handleSubmit}
            patientAnswers={patientAnswers}
            setPatientAnswers={setPatientAnswers}
          />
        </>
      );
    case 'diary_finish':
      return <DiaryThanksPage />;
    default:
      return <div>{drawStatusBar('Something went wrong. Please, reload the page and try again.')}</div>;
  }
};
